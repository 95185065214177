import React from "react"
import StyledSocialLinks from "../styledComponents/socialLinks"
import Icon from "./icon"
import { 
    analyticClickEvent
} from "../components/googleAnalytics"

const ShareTools = ( {shareTitle, pageLocation, postTitle} ) => {
  const gaLabelLinkedIn = 'Share : LinkedIn : ' + postTitle
  const gaLabelFaceBook = 'Share : FaceBook : ' + postTitle
  const gaLabelTwitter = 'Share : Twitter : ' + postTitle
  const gaLabelWhatsApp = 'Share : WhatsApp : ' + postTitle
  return (
    <StyledSocialLinks className="social-links">
      <span>{shareTitle}</span>
      <ul>
      <li key="facebook">
          <a
            onClick={() => analyticClickEvent('click',"share",gaLabelFaceBook)}
            target="_blank"
            href={
              pageLocation
                ? `https://www.facebook.com/sharer/sharer.php?u=${pageLocation}`
                // ? `https://www.facebook.com/dialog/share?add_id=170732476385329&display=popup&href=https://www.karinagrant.co.uk${pageLocation}&redirect_uri=https://www.karinagrant.co.uk${pageLocation}`
                : `https://www.facebook.com/karinagrantqt`
            }
          >
            <Icon shareTitle={shareTitle} name="facebook" />
          </a>
        </li>
        <li key="twitter">
          <a
            onClick={() => analyticClickEvent('click',"share",gaLabelTwitter)}
            target="_blank"
            href={
              pageLocation
                ? `http://twitter.com/share?text=Karina Grant - ${postTitle}&url=${pageLocation}`
                : `https://twitter.com/karinagrant`
            }
          >
            <Icon shareTitle={shareTitle} name="twitter" />
          </a>
        </li>
        <li key="linkedin">
          <a
            onClick={() => analyticClickEvent('click',"share",gaLabelLinkedIn)}
            target="_blank"
            href={
              pageLocation
                ? `https://www.linkedin.com/sharing/share-offsite/?url=${pageLocation}`
                : `https://www.linkedin.com/in/karina-grant-8b560659/`
            }
          >
            <Icon shareTitle={shareTitle} name="linkedin" />
          </a>
        </li>
        {/* <li key="instagram">
          <a
            onClick={() => analyticClickEvent('click',"share",gaLabelInstagram)}
            target="_blank"
            href={
              pageLocation
                ? `https://www.linkedin.com/sharing/share-offsite/?url=${pageLocation}`
                : `https://www.linkedin.com/in/karina-grant-8b560659/`
            }
          >
            <Icon shareTitle={shareTitle} name="instagram" />
          </a>
        </li> */}
        <li key="whatsapp">
          <a
            onClick={() => analyticClickEvent('click',"share",gaLabelWhatsApp)}
            target="_blank"
            href={`whatsapp://send?text=Karina Grant - ${postTitle} - ${pageLocation}`
            }
          >
            <Icon shareTitle={shareTitle} name="whatsapp" />
          </a>
        </li>
      </ul>
    </StyledSocialLinks>
  )
}
export default ShareTools
