import React, { useEffect, useState } from "react"
import {
  StyledEventList,
  StyledEventListItem,
  StyledViewAllEventsButtonWrapper
} from "../styledComponents/eventArchive"
import { StyledLinkButton } from "../styledComponents/button"
import PrimaryEventCard from "./EventCards/PrimaryEventCard"

const EventList = ({ workshops, eventType, pageType, padding, isHomePage }) => {
  // const [countryCode, setCountryCode] = useState("") // store token  
  // console.log('workshops',workshops)
  // useEffect(() => {
  //   fetch('/geolocation', {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //     }
  //   })
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (geoLocationData) {
  //       setCountryCode(geoLocationData?.geo?.country?.code)
  //     })
  // }, [])
  return (
    <>
      <StyledEventList>
        {workshops.map((post, index) => {

          let inputDateTimeStart = post.eventsMetaData.datetime
          if (inputDateTimeStart) {
            let fields = inputDateTimeStart.split('_')
            var date = fields[0];
            var time = fields[1];
            var timeNoSec = time.slice(0, -3);
          }
          var workshopDate = new Date(date)
          var daysBefore21 = new Date(workshopDate)
          daysBefore21.setDate(daysBefore21.getDate() - 20)
          var today = new Date()
          var Difference_In_Time = daysBefore21.getTime() - today.getTime();
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

          let eventPassed = false 
          if (workshopDate <= today) {
            eventPassed = true
          }
          let showEarlyBird = false
          if (today <= daysBefore21) {
            showEarlyBird = true
          } else {
            showEarlyBird = false
          }
          if (!eventPassed ) {
            return (
              <StyledEventListItem key={post.uri}>
                <PrimaryEventCard
                  eventTitle={post.title}
                  excerpt={post.excerpt}
                  earlyBird={showEarlyBird}
                  uri={post.uri}
                  eventId={post.id}
                  eventType={post.eventType}
                  featuredImage={post.image}
                  datetime={post.eventsMetaData.datetime}
                  datetimeend={post.eventsMetaData.datetimeend}
                  time={post.eventsMetaData.time}
                  timeZone={post.eventsMetaData.timezone}
                  eventLocation={post.eventsMetaData.location}
                  locationUrl={post.eventsMetaData.locationUrl}
                  streetaddress={post.eventsMetaData.streetaddress}
                  addresslocality={post.eventsMetaData.addresslocality}
                  postalcode={post.eventsMetaData.postalcode}
                  addressregion={post.eventsMetaData.addressregion}
                  addresscountry={post.eventsMetaData.addresscountry}
                  priceSale={post.eventsMetaData.priceSale}
                  price={post.eventsMetaData.price}
                  currency={post.eventType === 'qt-becoming-more'? '$' : '£'}
                  lastfewspaces={post.eventsMetaData.lastspaces}
                  lastFewDaysEarlyBird={post.eventsMetaData.lastfewdaysearlybird}
                  onlineandlive={post.eventsMetaData.onlineandlive}
                  eventdescription={post.excerpt}
                />
              </StyledEventListItem>
            )
          }
        })}
      </StyledEventList>
      {isHomePage &&
        <StyledViewAllEventsButtonWrapper><StyledLinkButton to="/events">View all events</StyledLinkButton></StyledViewAllEventsButtonWrapper>
      }
    </>
  )
}

export default EventList
