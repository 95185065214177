import styled from "styled-components"
import { regular14 } from "./typography"
import breakpoints from "./breakpoints"

const StyledSocialLinks = styled.div`
  margin: 0.8rem 0 0;
  line-height: 1.6rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  @media screen and (min-width: 1300px) {
    margin: 0.8rem auto 0;
  }
  span {
    position: absolute;
    top: -50000px;
    left: -50000px;
      @media screen and (min-width: ${breakpoints.md}px) {
        display:none;
      }
  }
  ul {
    display: none;
    list-style-type: none;
    margin: 0 0 0 0.8rem;
    padding: 0;
    display: inline-block;
    height: 2.4rem;
  }
  li {
    margin-right: 0.8rem;
    display: inline-block;

    &:last-child{
      margin-right: 0;
    }
  }
  a {
    border-bottom: 0;

    &:hover,
    &:focus {
      border-bottom: 0;
      svg path {
        fill: var(--light-green-dark);
      }
    }

    svg {
      path {
        fill: var(--light-green-darkest);
      }
      circle.insta-circle,
      rect {
        fill: var(--light-green-darkest);
      }
    }
    
    svg:hover { 
      background: var(--light-green-darkest);
      border-radius: 50%;
      circle.insta-circle,
      rect {
        fill: var(--white);
      }
      path {
        fill: var(--white);
        &.outer-circle {
          display: none;
        }
    }    
  }
`

export default StyledSocialLinks
