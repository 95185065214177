import React from "react"
import { StaticImage } from "gatsby-plugin-image";

const WorkshopImage = props => {

  const { eventType } = props
  return (
    <>
      {eventType === 'qt-level-1' ?
        <><StaticImage
          src="../images/workshops/quantum-touch/level-1-min.jpg"
          alt="Quantum-Touch Level 1 Workshop Image"
        /></> : eventType === 'qt-level-2' ?
          <StaticImage
            src="../images/workshops/quantum-touch/level-2-min.jpg"
            alt="Quantum-Touch Level 2 Workshop Image"
          /> : eventType === 'qt-supercharging' ?
            <StaticImage
              src="../images/workshops/quantum-touch/supercharging-min.jpg"
              alt="Quantum-Touch Supercharging"
            /> : eventType === 'qt-self-created-health' ?
              <StaticImage
                src="../images/workshops/quantum-touch/self-created-health-min.jpg"
                alt="Quantum-Touch Self Created Health"
              /> : eventType === 'qt-energy-boost' ?
              <StaticImage
                src="../images/workshops/quantum-touch/energy-boost-mini-min.jpg"
                alt="Quantum-Touch Energy Boost"
              />
              : eventType === 'qt-becoming-more' ?
              <StaticImage
                src="../images/workshops/quantum-touch/becoming-more-richard-gordon-min.jpg"
                alt="Quantum-Touch Becoming MOre"
              />
              : eventType === 'qt-manifesting-miracles' ?
              <StaticImage
                src="../images/workshops/quantum-touch/manifesting-miracles.jpg"
                alt="Quantum-Touch Manifesting Miracles"
              />
              :
                <StaticImage
                  src="../images/programs/quantum-touch/level-1-min.jpg"
                  alt="Quantum-Touch"
                />
      }
    </>
  )
}

export default WorkshopImage
