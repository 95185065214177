/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  description,
  lang,
  meta,
  title,
  image,
  url,
  isEventArticle,
  postData,
  priceSale,
  price,
  currency
}) => {

  const { wpUser } = useStaticQuery(
    graphql`
      query {
        # if there's more than one user this would need to be filtered to the main user
        wpUser {
          twitter: name
        }
      }
    `
  )

  const metaDescription = description || `Karina Grant is a energy and chakra healer working online and from the world renowned Hale Clinic in London.`
  const defaultTitle = `Energy Healing & Chakra Balancing`
  let ldJsonOnline
  let ldJsonInPerson
  if (isEventArticle) {
    let eventtitle = title
    let eventStartDate = postData.eventsMetaData.datetime.replace(/_/g, "T")
    let eventEndDate
    if (postData.eventsMetaData.datetimeend){
      eventEndDate = postData.eventsMetaData.datetimeend.replace(/_/g, "T")
    } else {
      eventEndDate = ''
    }
    let postcode = postData.eventsMetaData.postalcode
    let location = postData.eventsMetaData.location
    let locationDescription = postData.eventsMetaData.locationdescription
    let locationUrl = `https://www.karinagrant.co.uk` + postData.uri
    let streetaddress = postData.eventsMetaData.streetaddress
    let addresslocality = postData.eventsMetaData.addresslocality
    let addressregion = postData.eventsMetaData.addressregion
    let addresscountry = postData.eventsMetaData.addresscountry
    let eventCurrency = currency
    let eventDescription = postData.excerpt.replace(/(<([^>]+)>)/gi, "")
    let eventValidFrom = postData.date
    ldJsonOnline = {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": `${eventtitle}`,
      "startDate": `${eventStartDate}`,
      "endDate": `${eventEndDate}`,
      "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
      "eventStatus": "https://schema.org/EventScheduled",
      "http://schema.org/image": {
        "@id": `${image}`
      },
      "location": {
        "@type": "VirtualLocation",
        "name": "Zoom Video Chat",
        "url": "https://us02web.zoom.us/j/7691315072",
        "description": `${locationDescription}`,
      },
      "description": `${eventDescription}`,
      "performer": {
        "@type": "Person",
        "name": "Karina Grant"
      },
      "offers": {
        "@type": "Offer",
        "url": `${locationUrl}`,
        "lowPrice": `${priceSale}`,
        "highPrice": `${price}`,
        "priceCurrency": `${eventCurrency}`,
        "availability": "https://schema.org/InStock",
        "validFrom": `${eventValidFrom}`
      },
      "organizer": {
        "@type": "Organization",
        "name": "Karina Grant",
        "url": "https://www.karinagrant.co.uk"
      }
    }
    ldJsonInPerson = {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": `${eventtitle}`,
      "startDate": `${eventStartDate}`,
      "endDate": `${eventEndDate}`,
      "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
      "eventStatus": "https://schema.org/EventScheduled",
      "http://schema.org/image": {
        "@id": `${image}`
      },
      "location": {
        "@type": "Place",
        "name": `${location}`,
        "description": `${locationDescription}`,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": `${streetaddress}`,
          "addressLocality": `${addresslocality}`,
          "postalCode": `${postcode}`,
          "addressRegion": `${addressregion}`,
          "addressCountry": `${addresscountry}`
        }
      },
      "description": `${eventDescription}`,
      "performer": {
        "@type": "Person",
        "name": "Karina Grant"
      },
      "offers": {
        "@type": "Offer",
        "url": `${locationUrl}`,
        "lowPrice": `${priceSale}`,
        "highPrice": `${price}`,
        "priceCurrency": `${eventCurrency}`,
        "availability": "https://schema.org/InStock",
        "validFrom": `${eventValidFrom}`
      },
      "organizer": {
        "@type": "Organization",
        "name": "Karina Grant",
        "url": "https://www.karinagrant.co.uk"
      }
    }
  }
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `fb:app_id`,
            content: '170732476385329',
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: image,
          },
          {
            property: `og:url`,
            content: `https://www.karinagrant.co.uk` + url,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: wpUser?.twitter || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `facebook-domain-verification`,
            content: `a9dippvqxv12iohvd88qwdlm5jgkh0`,
          },
        ].concat(meta)}
      />

      {isEventArticle && !postData.eventsMetaData.postalcode ?
        <Helmet>
          <script id="0" type="application/ld+json">
            {JSON.stringify(ldJsonOnline)}
          </script>
        </Helmet>
        : isEventArticle &&
        <Helmet>
          <script id="0" type="application/ld+json">
            {JSON.stringify(ldJsonInPerson)}
          </script>
        </Helmet>
      }
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
